@import "/public/css/variables";

.checkbox {
  label {
    display: block;
    position: relative;
    transition: $trans;
    cursor: pointer;
  }
  input {
    position: absolute;
    opacity: 0;
  }
}

.text {
  display: block;
  box-sizing: border-box;
  padding-left: 40px;
  color: $black;
  font-size: $font-base;
  line-height: 24px;
  a {
    text-decoration: underline;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    border: solid 1px $gray;
    background: #EEEEEE
  }

  input:checked + &::after {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 8px;
    box-sizing: border-box;
    width: 8px;
    height: 16px;
    border-right: 3px solid $black;
    border-bottom: 3px solid $black;
    border-radius: 5%;
    transform: rotate(45deg);
  }
}

.item {
  &:not(last-child) {
    margin-bottom: 8px;
  }
}

.errorText {
  margin-bottom: 8px;
  padding-left: 16px;
  background: url("../../../../public/images/red/ico_exclamation.svg") no-repeat left center / 12px 12px;
  color: $main;
  font-size: $font-small;
}