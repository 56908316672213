@import "/public/css/variables";

.box {
  &[class^="Toastify__toast-container"] {
    top: auto;
    right: 0;
    bottom: 40px;
    left: auto;
    width: auto;
  }
}

.toast {
  &[class^="Toastify__toast"] {
    width: 320px;
    min-height: 0;
    padding: 16px;
    border-left: solid 2px $accent-green;
    border-radius: 0;
    background: $white;
    box-shadow: $box-shadow;
    color: $accent-green;
  }
}

.toast_error {
  &[class^="Toastify__toast"] {
    width: 320px;
    min-height: 0;
    padding: 16px;
    border-left: solid 2px $main;
    border-radius: 0;
    background: $white;
    box-shadow: $box-shadow;
    color: $main;
  }
}

.body {
  &[class^="Toastify__toast-body"] {
    margin: 0 16px 0 0;
    padding: 0;
  }

  div {
    &[class^="Toastify__toast-icon"] {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
}

.close {
  width: 24px;
  height: 24px;
  background-color: transparent;
  background: url("../../../../public/images/gray/ico_cross.svg") no-repeat
    center / 12px 12px;
  font-size: 0;
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
}
