@import "/public/css/variables";

.text {
  margin-bottom: 40px;
  text-align: center;
  white-space: pre-wrap;

  &.isNarrow {
    margin-bottom: 16px;
  }
}
