@import "/public/css/variables";

.textarea {
  box-sizing: border-box;
  width: 100%;
  height: 120px;
  padding: 16px;
  border-radius: $radius;
  background: $white;
  outline: solid 1px $gray-light3;
  resize: vertical;

  &::placeholder {
    color: $gray-light;
  }

  &:not(:placeholder-shown) {
    outline: solid 1px $gray-light3;
    background: $white;
  }

  &:focus {
    outline: solid 1px $accent-blue;
    background: $white;
  }

  &.isError {
    outline: none;
    background: $main-pale;
  }
}

.errorText {
  margin-bottom: 8px;
  padding-left: 16px;
  background: url("../../../../public/images/red/ico_exclamation.svg") no-repeat left
    center / 12px 12px;
  color: $main;
  font-size: $font-small;
}
