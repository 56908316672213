@import "/public/css/mixin";
@import "/public/css/variables";

.linkWrap {
  margin-top: 24px;
  text-align: center;
}

.link {
  transition: $trans;
  color: $black;
  font-size: $font-small;
  text-decoration: underline;

  @include mq-set("pc") {
    &:hover {
      text-decoration: none;
    }
  }
}
