@import "/public/css/variables";
@import "/public/css/mixin";

.contract {
  h1 {
    margin-bottom: 24px;
    font-size: $heading2;
    font-weight: normal;
    text-align: center;
    white-space: pre-wrap;
    padding: 0.5rem 0 2rem 0;
    @include mq-set("sp") {
      font-size: 20px;
    }
  }
  .halfSpace {
    display: inline-block;
    margin: 0.3rem;
  }
  .info {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .content {
    margin: 0.3rem 0 0.3rem 0;
  }
  .rule {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    ul {
      li {
        display: list-item;
        list-style: auto;
        margin-left: 1rem;
        span {
          display: block;
          padding-right: 4px;
        }
      }
    }
  }

  .noneList {
    ul {
      li {
        list-style: none;
      }
    }
  }

  .twoLevel {
    ol {
      li {
        display: flex;
        margin-left: inherit;
        counter-increment: cnt;
      }
      li:before {
        display: block;
        content: "（" counter(cnt) "）";
      }
    }
  }

  .threeLevel {
    ol.TwoLev {
      li {
        display: flex;
        margin-left: inherit;
        counter-increment: cnt;
      }
      li:before {
        display: block;
        content: "（" counter(cnt) "）";
      }
      ol.ThreeLev {
        li {
          display: flex;
          margin-left: 0.5rem;
          counter-increment: cnt2;
        }
        li:before {
          display: block;
          content: "（" counter(cnt2, katakana) "）";
        }
      }
    }
  }
  .callCenter {
    margin-left: 1rem;
  }
}
