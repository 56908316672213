@import "/public/css/mixin";
@import "/public/css/variables";

.photo {
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    right: 16px;
    bottom: 16px;
    width: 16px;
    height: 16px;
    background: url("../../../../public/images/white/ico_zoom-in.svg") no-repeat center /
      contain;
    @include mq-set("sp") {
      right: 8px;
      bottom: 8px;
    }
  }

  img {
    width: 100%;
  }
}

.modalPhoto {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 800px;
}
