@import "/public/css/variables";

.wrapper {
  position: relative;
}

.input {
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  border-radius: $radius;
  background: $white;
  outline: solid 1px $gray-light3;
  font-size: $font-medium;

  &::placeholder {
    color: $gray-light;
  }

  &:not(:placeholder-shown) {
    outline: solid 1px $gray-light3;
    background: $white;
  }

  &:focus {
    outline: solid 1px $accent-blue;
    background: $white;
  }

  &.isError {
    outline: none;
    background: $main-pale;
  }
}

.errorText {
  margin-bottom: 8px;
  padding-left: 16px;
  background: url("../../../../public/images/red/ico_exclamation.svg") no-repeat left
    center / 12px 12px;
  color: $main;
  font-size: $font-small;
}

.subText {
  margin-top: 8px;
  font-size: $font-small;
  white-space: pre-wrap;
}

.eye {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
  background: url("../../../../public/images/black/ico_eye_on.svg") no-repeat center / 20px
    14px;
  cursor: pointer;

  &.isHide {
    background-image: url("../../../../public/images/black/ico_eye_off.svg");
    background-size: 20px 17px;
  }
}
