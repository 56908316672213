@import "/public/css/variables";

.wrapper {
  position: relative;
}

.input {
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  border-radius: $radius;
  background: $white;
  outline: solid 1px $gray-light3;
  font-size: $font-medium;

  &::placeholder {
    color: $gray-light;
  }

  &:not(:placeholder-shown) {
    outline: solid 1px $gray-light3;
    background: $white;
  }

  &:focus {
    outline: solid 1px $accent-blue;
    background: $white;
  }

  &.isError {
    outline: none;
    background: $main-pale;
  }
}

.errorText {
  margin-bottom: 8px;
  padding-left: 16px;
  background: url("../../../../public/images/red/ico_exclamation.svg") no-repeat left center / 12px 12px;
  color: $main;
  font-size: $font-small;
}

.calendar {
  border-color: $gray-light3;
  color: $black;
  div {
    &[class^="react-datepicker__header"] {
      background-color: $gray-pale2;
      border-color: $gray-light3;
    }

    &[class^="react-datepicker__current-month"],
    &[class^="react-datepicker__day-name"] {
      color: $black;
    }

    &[class^="react-datepicker__day"] {
      color: $black;
      &:hover {
        background-color: $gray-pale2;
      }
    }

    &[class*="react-datepicker__day--selected"],
    &[class*="react-datepicker__day--keyboard-selected"] {
      background-color: $accent-blue;
      color: $white;
      &:hover {
        background-color: $accent-blue;
        color: $white;
      }
    }

    &[class*="react-datepicker__day--today"] {
      background-color: $white;
      color: $black;
      font-weight: normal;
    }

    &[class*="react-datepicker__day--disabled"] {
      color: $gray-light;
    }
  }

  span {
    &[class^="react-datepicker__navigation-icon"] {
      &::before {
        border-color: $black;
      }
    }
  }
}
