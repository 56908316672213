@import "/public/css/variables";

.proxy_input {
  margin: 1rem;
  h1 {
    font-size: $heading2;
    text-align: center;
    padding: 1rem 0 1.5rem 0;
  }
  .list {
    display: flex;
    span {
      display: block;
      padding-right: 4px;
    }
  }
}
