@import "/public/css/mixin";
@import "/public/css/variables";

.select {
  width: 100%;
  max-width: 400px;
  margin: 0 auto 40px;
}

.text {
  text-align: center;
}

.subText {
  margin-top: 24px;
  font-size: $font-small;
  text-align: center;

  p:not(:last-child) {
    margin-bottom: 8px;
  }
}

.button {
  display: flex;
  justify-content: right;
  margin-top: 16px;
}

.list[class^="react-slidedown"] {
  margin-top: 16px;
  transition-duration: $trans;
}

.log {
  margin: 0 -16px -16px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
