@import "/public/css/mixin";
@import "/public/css/variables";

.inner {
  width: 100%;
  margin: 0 -16px;
  padding: 40px 16px;
  box-shadow: $box-shadow;
  background: $white;

  &.isWide {
    padding: 64px 16px;
  }

  &.isNarrow {
    padding: 16px;

    & + & {
      margin-top: 16px;
    }
  }

  @include mq-set("sp") {
    padding: 24px 16px 40px;

    &.isWide {
      padding: 40px 16px;
    }
  }
}
