@import "/public/css/variables";
@import "/public/css/mixin";

.wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  @include mq-set("sp") {
    justify-content: flex-start;
  }
}

.list {
  color: $main;
  font-size: $font-small;
}

.item {
  padding-left: 1.5em;
  text-indent: -1.5em;

  &::before {
    content: "※";
    margin-right: 0.5em;
  }
}
