@import "/public/css/mixin";
@import "/public/css/variables";

.commonFlame {
  display: flex;
  position: relative;
  z-index: 2;
  flex: 1;
  align-items: center;
}

.flame {
  padding: 14px 0 14px 24px;
}

.narrowFlame {
  display: flex;
  position: relative;
  z-index: 2;
  flex: 1;
  align-items: center;
  padding: 14px 0 14px 24px;
  @include mq-set("sp") {
    padding: 14px 0 14px 10px;
  }
}

.title {
  margin-left: 16px;
  margin-top: 5px;
  padding-top: 1px;
  font-size: 16px;
  font-weight: bold;

  @include mq-set("sp") {
    margin-left: 6px;
    font-size: 13px;
    letter-spacing: -0.5px;
  }

  @include mq-set("narrow-sp") {
    margin-left: 6px;
    font-size: 12px;
    letter-spacing: -1.5px;
  }
}

.premiumLogo {
  width: 120px;

  @include mq-set("sp") {
    width: 95px;
  }

  @include mq-set("narrow-sp") {
    width: 90px;
  }
}

.recruitLogo {
  width: 200px;

  @include mq-set("sp") {
    width: 150px;
  }
}

.noLogo {
  min-height: 13px;
}
