@import "/public/css/mixin";
@import "/public/css/variables";

.wrapper {
  margin-bottom: 24px;
  padding: 16px;
  border: solid 1px $main;
  border-radius: $radius;

  &.isBottom {
    margin: 40px 0;
  }
}

.text {
  padding-left: 20px;
  background: url("../../../../public/images/red/ico_exclamation.svg") no-repeat left
    center / 12px 12px;
  color: $main;
  font-size: $font-small;
  white-space: pre-wrap;

  a {
    transition: $trans;
    color: $main;
    text-decoration: underline;

    @include mq-set("pc") {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
