@import "/public/css/mixin";
@import "/public/css/variables";

.header {
  position: relative;
  border-top: 4px solid $main;
  background: $white;
  box-shadow: $box-shadow;
}

.inner {
  display: flex;
  justify-content: space-between;

  @include mq-set("sp") {
    align-items: center;
  }
}

.name {
  @include mq-set("pc") {
    display: flex;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    align-items: center;
    min-width: 200px;
    padding: 14px 56px 14px 24px;
    transition: $trans;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      background: $base;
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 24px;
      width: 16px;
      height: 10px;
      transform: translateY(-50%);
      transition: $trans;
      background: url("../../../../public/images/black/ico_arrow.svg") no-repeat
        center center / contain;
    }

    &.isOpen::after {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  @include mq-set("sp") {
    padding: 14px 16px 14px 14px;
  }
}

.line {
  @include mq-set("sp") {
    display: flex;
    position: relative;
    justify-content: center;
    width: 25px;
    height: 2px;
    transition: 0.2s linear;
    background: #333;

    &.isOpen {
      background: transparent;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 25px;
      height: 2px;
      transition: 0.2s linear;
      background: #333;
    }
    &::before {
      top: -8px;
    }
    &::after {
      top: 8px;
    }

    &.isOpen::before {
      top: 0;
      transform: rotate(-45deg);
    }
    &.isOpen::after {
      top: 0;
      transform: rotate(45deg);
    }
  }
}

.menu {
  padding: 0 8px;
  border-radius: $radius;
  background: $white;
  box-shadow: $box-shadow;

  @include mq-set("sp") {
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    padding: 0;
    animation: show 0.2s;
    border-radius: initial;
    box-shadow: none;
  }
}

.panel[class^="react-slidedown"] {
  @include mq-set("pc") {
    position: absolute;
    right: 0;
    margin-top: 4px;
    padding: 0 8px;
    transition-duration: initial;
    transition-timing-function: initial;
  }
  @include mq-set("sp") {
    height: 100vh;
    transition-duration: 0.3s;
  }
}

.item {
  padding: 8px 0;

  @include mq-set("sp") {
    &:nth-child(odd) {
      background: $base;
    }
  }

  &:nth-child(n + 2) {
    border-top: 1px solid $gray-pale;

    @include mq-set("sp") {
      border-top: 0;
    }
  }
}

.link {
  display: block;
  position: relative;
  box-sizing: border-box;
  padding: 8px 8px 8px 40px;
  transition: $trans;
  border-radius: $radius;
  color: $black;
  font-size: 16px;
  cursor: pointer;

  @include mq-set("pc") {
    &:hover {
      background: $base;
    }
  }

  @include mq-set("sp") {
    width: 100%;
    padding-left: 48px;
    &::before {
      left: 16px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 8px;
    width: 16px;
    height: 14px;
    transform: translateY(-50%);
  }
}

.home {
  &::before {
    background: url("../../../../public/images/black/ico_home.svg") no-repeat
      center center / contain;
  }
}

.user {
  &::before {
    background: url("../../../../public/images/black/ico_user.svg") no-repeat
      center center / contain;
  }
}

.inquiry {
  &::before {
    background: url("../../../../public/images/black/ico_mail.svg") no-repeat
      center center / contain;
  }
}

.logout {
  &::before {
    background: url("../../../../public/images/black/ico_logout.svg") no-repeat
      center center / contain;
  }
}
