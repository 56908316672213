@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap");

html {
  height: 100%;
}

div,
p,
input,
textarea,
button,
select {
  font-family: "Noto Sans JP", sans-serif;
}

html,
body,
div,
span,
dl,
dt,
dd,
ol,
ul,
li,
form,
label,
table,
tbody,
tfoot,
thead,
input,
textarea,
tr,
th,
td,
footer,
header,
nav,
section,
select,
img,
h1,
h2,
h3,
div,
p,
figure {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: bottom;
  background: transparent;
}

a {
  text-decoration: none;
}

a,
button {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

button {
  background: none;
  margin: 0;
  padding: 0;
  border: 0;
}

img {
  display: block;
}

input:disabled,
textarea:disabled {
  opacity: 1;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ol,
ul,
li,
dt,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

body {
  box-sizing: border-box;
  height: 100%;
  background: #fff;
  color: #333;
  font-size: 14px;
  font-family: "Noto Sans JP", sans-serif;
  line-height: 1.5;
  letter-spacing: 0.04em;
  -webkit-text-size-adjust: 100%;
}

@media screen and (max-width: 767.99px) {
  .isOnlyPc {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .isOnlySp {
    display: none;
  }
}
