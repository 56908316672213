//// カラー定義 ////

//白
$white: #fff;

//黒
$black: #333;

//グレー
$gray: #666;
$gray-light: #999;
$gray-light2: #bababa;
$gray-light3: #ccc;
$gray-pale: #e0e0e0;
$gray-pale2: #f4f4f4;

//メインカラー
$main-dark: #ad000e;
$main: #e60012;
$main-light: #f38089;
$main-pale: #fde6e7;

// ベースカラー
$base: #faf7f7;

// アクセントカラー
$accent-green: #00ad49;
$accent-blue: #41b3d7;
$accent-blue-dark: #4159d7;

//文字サイズ

//見出し
$heading1: 32px;
$heading2: 24px;
$heading3: 16px;

//テキスト
$font-large: 18px;
$font-medium: 16px;
$font-base: 14px;
$font-small: 12px;

//アニメーション
$trans: 0.2s;

//角丸
$radius: 4px;

//ボックスシャドウ
$box-shadow: 0 0 3px rgba(#000, 0.1);

//透明
$opacity: 0.7;
