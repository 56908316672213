@import "/public/css/variables";

.caution {
  font-size: 0.75rem;
  display: flex;
  padding-top: 8px;
  padding-left: 2px;
  @media screen and (max-width: 376px) {
    font-size: 0.68rem;
  }
}

.caution::before {
  content: "※";
}
