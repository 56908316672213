@import "/public/css/mixin";
@import "/public/css/variables";

//ボタン
.button {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  height: 56px;
  transition: $trans;
  border-radius: $radius;
  background: $main;
  color: $white;
  font-size: $font-medium;
  text-align: center;
  cursor: pointer;

  @include mq-set("pc") {
    &:hover {
      background: $main-dark;
    }
  }

  & + & {
    margin-top: 16px;
  }
}

.isWhiteBase {
  border: solid 1px $main;
  background: $white;
  color: $main;

  @include mq-set("pc") {
    &:hover {
      background: $base;
    }
  }
}

.isWhiteGray {
  border: solid 1px $gray-pale;
  background: $white;
  color: $black;

  @include mq-set("pc") {
    &:hover {
      background: $gray-pale2;
    }
  }
}

.isSmall {
  width: auto;
  height: auto;
  padding: 8px 12px;
  font-size: $font-small;
}

.isToggleIcon {
  padding-right: 24px;

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 8px;
    width: 8px;
    height: 4px;
    transform: translateY(-50%);
    transition: $trans;
    background: url("../../../../public/images/black/ico_arrow_small.svg") no-repeat center /
      contain;
  }

  &.isOpen {
    &::after {
      transform: translateY(-50%) rotate(-180deg);
    }
  }
}

//ボタンボックス
.box {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 40px auto 0;

  &.isNarrow {
    margin: 24px auto 0;
  }
}

.disabled {
  &:disabled {
    opacity: 0.5;
    background-color: gray;
  }
}