$sizes: pc, sp, narrow-sp;

//メディアクエリ
$breakpoint-set: (
  "pc": "screen and (min-width: 768px)",
  "sp": "screen and (max-width: 767.9px)",
  "narrow-sp": "screen and (max-width: 350px)",
) !default;

@mixin mq-set($breakpoint: sp) {
  @media #{map-get($breakpoint-set, $breakpoint)} {
    @content;
  }
}
