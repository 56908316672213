@import "/public/css/variables";

.wrapper {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 16px;
    width: 16px;
    height: 10px;
    transform: translateY(-50%);
    transition: $trans;
    background: url("../../../../public/images/black/ico_arrow.svg") no-repeat center center / contain;
  }
}

.input {
  box-sizing: border-box;
  width: 100%;
  padding: 16px 40px 16px 16px;
  border: solid 1px $gray-light3;
  border-radius: $radius;
  background: $white;
  font-size: $font-medium;
  cursor: pointer;

  &.isError {
    border-color: $main-pale;
    background: $main-pale;
  }
}

.errorText {
  margin-bottom: 8px;
  padding-left: 16px;
  background: url("../../../../public/images/red/ico_exclamation.svg") no-repeat left center / 12px 12px;
  color: $main;
  font-size: $font-small;
}

.time {
  border-color: $gray-light3;
  color: $black;
  font-size: $font-base;

  div {
    &[class^="react-datepicker__header"] {
      display: none;
    }

    &[class^="react-datepicker__triangle"] {
      &::before {
        border-bottom-color: $gray-light3 !important;
      }
      &::after {
        border-bottom-color: $white !important;
      }
    }

    &[class^="react-datepicker__time"] {
      &[class^="react-datepicker__time-box"] {
        ul[class^="react-datepicker__time-list"] {
          li {
            &[class^="react-datepicker__time-list-item"] {
              height: auto;
              padding: 8px;
              &:hover {
                background-color: $gray-pale2;
              }
            }

            &[class*="react-datepicker__time-list-item--disabled"] {
              color: $gray-light;
            }

            &[class*="react-datepicker__time-list-item--selected"] {
              background-color: $accent-blue;
              color: $white;
              &:hover {
                background-color: $accent-blue;
              }
            }
          }
        }
      }
    }
  }
}
