@import "/public/css/mixin";
@import "/public/css/variables";

@mixin schedule {
  flex: 1;
  padding: 0 32px;
  text-align: center;

  .isVertical & {
    padding: 0 16px;
    text-align: left;
  }

  @include mq-set("sp") {
    padding: 0 16px;
    text-align: left;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-radius: $radius;
  background: $base;

  @include mq-set("sp") {
    display: block;
  }
}

.isVertical {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 24px;
}

.date {
  @include schedule;
}

.item {
  @include schedule;

  position: relative;

  @include mq-set("sp") {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &:not(:first-child) {
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 1px;
      height: 48px;
      transform: translateY(-50%);
      background: $main-light;

      .isVertical & {
        content: none;
      }

      @include mq-set("sp") {
        content: none;
      }
    }

    &.isShort {
      &::before {
        height: 40px;
      }
    }
  }
}

.number {
  font-size: $font-large;
  font-weight: bold;

  .isVertical & {
    font-size: $font-medium;
  }

  @include mq-set("sp") {
    display: inline;
    vertical-align: baseline;
  }
}

.title {
  display: block;
  margin-bottom: 4px;
  font-size: $font-small;
  font-weight: bold;
}

.shop {
  color: $gray-light;
  font-size: $font-small;

  &:first-of-type {
    margin-top: 4px;
  }
}

.link {
  a {
    margin-top: 4px;
    color: $accent-blue-dark;
    font-size: $font-small;
    text-decoration: underline;

    @include mq-set("pc") {
      &:hover {
        text-decoration: none;
      }
    }
  }

  @include mq-set("sp") {
    text-align: right;
  }
}

.attention {
  padding-left: 1em;
  color: $main;
  font-size: $font-small;
  text-align: left;
  text-indent: -1em;
  letter-spacing: -0.01em;
}

.reserved {
  color: $main;
  font-size: $font-small;
  font-weight: bold;
}
