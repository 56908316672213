@import "/public/css/mixin";
@import "/public/css/variables";

.outer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.wrapper {
  flex: 1;
  background: $base;
}

.main {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  padding: 80px 16px 120px;
  box-sizing: border-box;

  &.isWide {
    max-width: 1024px;
  }

  @include mq-set("sp") {
    padding: 40px 16px 80px;
  }
}
