@import "/public/css/variables";

.footer {
  padding: 7px;
  border-top: 1px solid $gray-pale;
  background: $base;
}

.copyright {
  color: $gray;
  font-size: 12px;
  text-align: center;
}
