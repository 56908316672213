@import "/public/css/mixin";
@import "/public/css/variables";

.title,
.item {
  display: flex;
}

.title {
  margin-bottom: 8px;
  color: $gray-light;
  font-size: $font-small;
  font-weight: bold;

  @include mq-set("sp") {
    display: none;
  }
}

.list {
  border-bottom: solid 1px $gray-light3;
}

.item {
  padding: 16px 16px 16px 0;
  border-top: solid 1px $gray-light3;

  @include mq-set("sp") {
    display: block;
    padding: 16px 0;
  }
}

.date {
  width: 120px;
  margin-right: 32px;

  @include mq-set("sp") {
    width: 100%;
    margin: 0 0 16px 0;

    &:before {
      content: "日時";
      display: block;
      margin-bottom: 4px;
      color: $gray-light;
      font-size: $font-small;
      font-weight: bold;
    }
  }
}

.description {
  flex-grow: 1;

  @include mq-set("sp") {
    &:before {
      content: "メンテナンス内容";
      display: block;
      margin-bottom: 4px;
      color: $gray-light;
      font-size: $font-small;
      font-weight: bold;
    }
  }
}
