@import "/public/css/mixin";
@import "/public/css/variables";

/* 1件, リスト共通 */
.about,
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  li {
    &:not(:last-of-type) {
      margin-right: 32px;

      @include mq-set("sp") {
        margin: 0 0 16px;
      }
    }
  }
}

.title,
.heading {
  color: $gray-light;
  font-size: 12px;
  font-weight: bold;
}

.date {
  @include mq-set("pc") {
    width: 80px;
  }
}

.shop {
  @include mq-set("pc") {
    flex: 1;
  }
}

.status {
  @include mq-set("pc") {
    width: 120px;
  }
}

.button {
  @include mq-set("pc") {
    width: 64px;
  }
}

/* 1件 */
.about {
  @include mq-set("sp") {
    display: block;
  }
}

.heading {
  display: block;
  margin-bottom: 4px;

  .about & {
    @include mq-set("pc") {
      display: none;
    }
  }
}

.down[class^="react-slidedown"] {
  transition-duration: $trans;
}

.detail {
  margin-top: 16px;
  padding-top: 16px;
  border-top: solid 1px $gray-light3;
}

.detailList {
  margin-bottom: 24px;
}

.detailItem {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.imageList {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  @include mq-set("sp") {
    gap: 8px;
  }
}

.imageItem {
  width: calc((100% - 32px) / 3);

  @include mq-set("sp") {
    width: calc((100% - 8px) / 2);
  }
}

/* リスト */
.title {
  margin-bottom: 8px;
  padding: 0 16px;

  @include mq-set("sp") {
    display: none;
  }
}

.item {
  padding: 16px;
  &:nth-child(odd) {
    background: $gray-pale2;
  }
}
