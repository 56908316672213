@import "/public/css/variables";

.heading1 {
  margin-bottom: 24px;
  font-size: $heading1;
  font-weight: normal;
  text-align: center;
  white-space: pre-wrap;
}

.heading2 {
  margin-bottom: 24px;
  font-size: $heading2;
  font-weight: normal;
  text-align: center;
  white-space: pre-wrap;
}

.heading3 {
  position: relative;
  margin-bottom: 16px;
  padding-left: 12px;
  font-size: $heading3;
  font-weight: bold;
  white-space: pre-wrap;

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 4px;
    height: 16px;
    transform: translateY(-50%);
    background: $main;
  }
}
