@import "/public/css/mixin";
@import "/public/css/variables";

.overlay {
  display: flex;
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background: rgba(#000, $opacity);
}

.modal {
  position: relative;
  width: 100%;
  max-width: 800px;
  max-height: 800px;
  margin: 0 16px;

  @include mq-set("sp") {
    max-height: 80%;
  }
}

.close {
  position: absolute;
  top: -32px;
  right: 0;
  width: 16px;
  height: 16px;
  transition: $trans;
  border-radius: 50%;
  font-size: 0;

  &::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("../../../../public/images/white/ico_cross.svg") no-repeat center /
      contain;
  }

  @include mq-set("pc") {
    &:hover {
      opacity: $opacity;
    }
  }
}
