@import "/public/css/variables";

.wrapper {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 16px;
    width: 16px;
    height: 10px;
    transform: translateY(-50%);
    transition: $trans;
    background: url("../../../../public/images/black/ico_arrow.svg") no-repeat center
      center / contain;
  }
}

.select {
  width: 100%;
  padding: 16px 40px 16px 16px;
  border: solid 1px $gray-light3;
  border-radius: $radius;
  background: $white;
  cursor: pointer;
  appearance: none;

  &.isError {
    border-color: $main-pale;
    background: $main-pale;
  }
}

.errorText {
  margin-bottom: 8px;
  padding-left: 16px;
  background: url("../../../../public/images/red/ico_exclamation.svg") no-repeat left
    center / 12px 12px;
  color: $main;
  font-size: $font-small;
}
