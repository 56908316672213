@import "/public/css/mixin";
@import "/public/css/variables";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-radius: $radius;
  background: $base;

  @include mq-set("sp") {
    display: block;
  }
}

.item,
.date {
  padding: 0 32px;
  text-align: center;

  @include mq-set("sp") {
    padding: 0 16px;
    text-align: left;
  }
}
.date {
  position: relative;
  flex: 1;

  @include mq-set("sp") {
    margin-top: 16px;
  }
}
.item {
  position: relative;
  flex: 1;

  @include mq-set("sp") {
    margin-top: 16px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    width: 1px;
    height: 40px;
    transform: translateY(-50%);
    background: $main-light;

    @include mq-set("sp") {
      content: none;
    }
  }
}

.number {
  display: block;
  font-size: $font-medium;
  font-weight: bold;

  @include mq-set("sp") {
    display: inline;
    vertical-align: baseline;
  }
}

.plan {
  @include mq-set("sp") {
    margin-left: 0.08em;
    font-size: $font-small;
    vertical-align: baseline;
  }
}

.title {
  display: block;
  margin-bottom: 4px;
  font-size: $font-small;
  font-weight: bold;
}

.shop {
  color: $gray-light;
  font-size: $font-small;
  &:first-of-type {
    margin-top: 4px;
  }
}

.attention {
  padding-left: 1em;
  color: $main;
  font-size: $font-small;
  text-align: left;
  text-indent: -1em;
  letter-spacing: -0.01em;
}

.attention2 {
  color: $main;
  font-size: $font-small;
  text-align: center;
  letter-spacing: -0.01em;
  font-weight: bold;
  @include mq-set("sp") {
    text-align: left;
  }
}
